<template>
  <div class="container">
      <table>
          <tr>
              <td colspan="4" class="title">{{contractNum}} 物料清单</td>
          </tr>
          <tr>
              <td>物料编码</td>
              <td>产品名称</td>
              <td>产品型号</td>
              <td>产品数量</td>
          </tr>
          <tr v-for="item in dataInfo" :key="item.id">
              <td>{{item.materialCode}}</td>
              <td>{{item.materialName}}</td>
              <td>{{item.model}}</td>
              <td>{{item.outCount}}</td>
          </tr>
      </table>
  </div>
</template>

<script>
export default {
    data(){
        return{
            dataInfo:[],
            contractNum:'',
        }
    },
    mounted(){
        window.print()
    },
    created(){
        this.dataInfo = JSON.parse(localStorage.getItem('materialData')).data;
        this.contractNum = JSON.parse(localStorage.getItem('materialData')).contractNum;
    },
}
</script>

<style lang="scss" scoped>
.container{
    width: 100%;
    height: 100%;
    // overflow-y: auto;
}

table{
    margin: 0 auto;
    width: 715px;
    border-collapse: collapse;
    table-layout: fixed;
    .title{
        height: 50px;
        line-height: 50px;
        font-weight: bold;
        font-size: 21px;
    }
    td{
        border: 1px solid #000;
        height: 30px !important;
        text-align: center;
    }
}
</style>